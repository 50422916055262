import React from "react"
import ImgLoading from "components/images/kementrian-bkpm.svg"
import ImgLoadingGif from "components/images/bkpm_loading.gif"

const LoadingBar = props => {
  return (
    <div
      id="loading"
      className="bg-primary"
      style={{
        display: "block",
        position: "absolute",
        width: "100%",
        height: "80vh",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <center>
          <img src={ImgLoading} className="md:w-[400px] w-[350px]" alt="BKPM" />
          <img
            className="pb-3"
            src={ImgLoadingGif}
            width="250px"
            alt="Loading..."
          />
        </center>
      </div>
    </div>
  )
}

export default LoadingBar
